<bluesky-feed class="{props.class}">
  <ul if={state['data']} class="list-unstyled">
    <li each={post in posts()} class="position-relative">
      <div class="text">
        {post.text()}
      </div>

      <div if={post.hasImages()} class="images">
        <div
          each={image in post.images()}
          class="ratio ratio-1x1 mb-3"
        >
          <img
            src={image['thumb']}
            class="rounded"
          />
        </div>
      </div>

      <a
        if={post.isExternalLink()}
        href={post.externalLink()['uri']}
        class="external"
      >
        <strong>{post.externalLink()['title']}</strong>
        <img src={post.externalLink()['thumb']} />
        <div class="description">
          {post.externalLink()['description']}
        </div>
      </a>

      <div class="time-stamp text-end">
        {post.createdAtHuman()}
      </div>

      <a href={post.url()} target="_blank" class="stretched-link"></a>

      <hr size="3" />
    </li>
  </ul>

  <script>
    import BlueSkyPost from '../lib/blue_sky_post'

    const api = 'https://public.api.bsky.app'
    // const account = 'chrmosimann.bsky.social'
    // const account = 'labonnelab.bsky.social'
    const account = 'baronelab.bsky.social'

    export default class {
      onBeforeMount(props, state) {
        let query = `actor=${account}&filter=posts_no_replies`
        let endpoint = `${api}/xrpc/app.bsky.feed.getAuthorFeed?${query}`

        fetch(endpoint).then(r => r.json()).then(d => {
          console.log(d)
          const data = d['feed'].map(e => new BlueSkyPost(e))
          this.update({data})
        })
      }

      posts() {
        return this.state['data'].filter(post => {
          // console.log(post, post.text())
          if (post.isRepost() || post.isBlueSkyRef()) return false

          return true
        })
      }
    }
  </script>
</bluesky-feed>