import * as Popper from "@popperjs/core"
import 'bootstrap'

import {VwUnit} from '@wendig/lib'

import {riot, bus} from './lib/riot'

import BlueskyFeed from './components/bluesky_feed.riot'
import BlueskyTeaser from './components/bluesky_teaser.riot'
import Cell from './components/cell.riot'
import Icon from './components/icon.riot'
import Projects from './components/projects.riot'
import Publications from './components/publications.riot'
import Raw from './components/raw.riot'
import Team from './components/team.riot'

riot.register('bluesky-feed', BlueskyFeed)
riot.register('bluesky-teaser', BlueskyTeaser)
riot.register('cell', Cell)
riot.register('icon', Icon)
riot.register('projects', Projects)
riot.register('publications', Publications)
riot.register('raw', Raw)
riot.register('team', Team)

const vw = new VwUnit()
vw.setup()

let isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) || 
  (
    navigator.platform === 'MacIntel' &&
    navigator.maxTouchPoints > 1
  )

if (isIOS) {
  document.querySelector('body').setAttribute('platform', 'ios')
}

riot.mount('[is]')
