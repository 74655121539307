<publications>
  <ul>
    <li class="d-flex" onclick={event => onClick(event)}>
      <img src="/assets/images/publication_1.jpg" />
      <div class="d-flex flex-column">
        <div class="authors">Barone V.*, Tagua A.*, Andrés-San Román J.A., Hamdoun A., Garrido-García A., Lyons D.C., Escudero, L.M.</div>
        <div class="title">Local and global changes in cell density induce reorganisation of 3D packing in a proliferating epithelium.</div>
        <div class="refs justify-self-end">
          Development 20, 151 (2024)
          <a href="https://doi.org/10.1242/dev.202362" target="_blank">doi.org/10.1242/dev.202362</a>
        </div>
      </div>
    </li>

    <li class="d-flex" onclick={event => onClick(event)}>
      <img src="/assets/images/publication_2.jpg" />
      <div class="d-flex flex-column">
        <div class="authors">Barone V., Byrne M. & Lyons D. C.</div>
        <div class="title">Lineage tracing shows that cell size asymmetries predict the dorsoventral axis in the sea star embryo. </div>
        <div class="refs justify-self-end">
          BMC Biol. 20, 179 (2022)
          <a href="https://doi.org/10.1186/s12915-022-01359-3" target="_blank">doi.org/10.1186/s12915-022-01359-3</a>
        </div>
      </div>
    </li>    

    <li class="d-flex" onclick={event => onClick(event)}>
      <img src="/assets/images/publication_3.jpg" />
      <div class="d-flex flex-column">
        <div class="authors">Barone V. & Lyons D. C. </div>
        <div class="title">Live imaging of echinoderm embryos to illuminate evo-devo. </div>
        <div class="refs justify-self-end">
          Front Cell Dev Biol 10, 1007775 (2022)
          <a href="https://doi.org/10.3389/fcell.2022.1007775" target="_blank">doi.org/10.3389/fcell.2022.1007775</a>
        </div>
      </div>
    </li>    

    <li class="d-flex" onclick={event => onClick(event)}>
      <img src="/assets/images/publication_4.jpg" />
      <div class="d-flex flex-column">
        <div class="authors">Barone V., Lang M., Krens S.F., Pour S.S., Sako K., Sikora M., Guet C., Heisenberg C.-P.</div>
        <div class="title">An Effective Feedback Loop between Cell-Cell Contact Duration and Morphogen Signaling Determines Cell Fate. </div>
        <div class="refs justify-self-end">
          Dev. Cell 43, 198–211.e12 (2017)
          <a href="https://doi.org/10.1016/j.devcel.2017.09.014" target="_blank">doi.org/10.1016/j.devcel.2017.09.014</a>
        </div>
      </div>
    </li>    

    <li class="d-flex" onclick={event => onClick(event)}>
      <img src="/assets/images/publication_5.jpg" />
      <div class="d-flex flex-column">
        <div class="authors">Krens S.F., Veldhuis J.H., Barone V., Čapek D., Maître J.L., Brodland G.W., Heisenberg C.-P.</div>
        <div class="title">Interstitial fluid osmolarity modulates the action of differential tissue surface tension in progenitor cell segregation during gastrulation. </div>
        <div class="refs justify-self-end">
          Development 144, 1798–1806 (2017)
          <a href="https://doi.org/10.1242/dev.144964" target="_blank">doi.org/10.1242/dev.144964</a>
        </div>
      </div>
    </li>    

    <li class="d-flex" onclick={event => onClick(event)}>
      <img src="/assets/images/publication_6.jpg" />
      <div class="d-flex flex-column">
        <div class="authors">Sako K., Pradhan S.J., Barone V., Ingles-Prieto A., Muller P., Ruprecht V., Čapek D., Galande S., Janovjak H., Heisenberg C.-P. </div>
        <div class="title">Optogenetic Control of Nodal Signaling Reveals a Temporal Pattern of Nodal Signaling Regulating Cell Fate Specification during Gastrulation. </div>
        <div class="refs justify-self-end">
          Cell Rep. 16,(3):866-77 (2016)
          <a href="https://doi.org/10.1016/j.celrep.2016.06.036" target="_blank">doi.org/10.1016/j.celrep.2016.06.036</a>
        </div>
      </div>
    </li>    

    <li class="d-flex" onclick={event => onClick(event)}>
      <img src="/assets/images/publication_7.jpg" />
      <div class="d-flex flex-column">
        <div class="authors">Barone V. & Heisenberg C.-P. </div>
        <div class="title">Cell adhesion in embryo morphogenesis. </div>
        <div class="refs justify-self-end">
          Curr. Opin. Cell Biol. 24, 148–153 (2012)
          <a href="https://doi.org/10.1016/j.ceb.2011.11.006" target="_blank">doi.org/10.1016/j.ceb.2011.11.006</a>
        </div>
      </div>
    </li>
  </ul>

  <script>
    import {util} from '@wendig/lib'

    export default class {
      onClick(event) {
        console.log(event.target.tagName)
        if (event.target.tagName != 'A') {
          const li = util.parents(event.target, 'li')
          li.querySelector('a').click()
        }
      }
    }
  </script>
</publications>