export default class BlueSkyPost {
  constructor(data) {
    this.d = data
  }

  id() {
    const parts = this.d['post']['uri'].split('/')
    return parts[parts.length - 1]
  }

  url() {
    const base = 'https://bsky.app/profile'
    return `${base}/${this.author()}/post/${this.id()}`
  }

  author() {
    return this.d['post']['author']['handle']
  }

  createdAt() {
    return new Date(this.d['post']['record']['createdAt'])
  }

  createdAtHuman() {
    return this.createdAt().toLocaleString([], {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: '2-digit', minute: '2-digit'
    })
  }

  text() {
    if (!this.d['post']['record']) return null

    return this.d['post']['record']['text']
  }

  images() {
    if (!this.hasImages()) return null

    return this.embed()['images']
  }

  externalLink() {
    if (!this.isExternalLink()) return null

    return this.embed()['external']
  }

  hasImages() {
    const type = 'app.bsky.embed.images#view'
    return this.hasEmbed() && this.embed()['$type'] === type
  }

  isBlueSkyRef() {
    const type = 'app.bsky.embed.record#view'
    return this.hasEmbed() && this.embed()['$type'] === type
  }

  isExternalLink() {
    const type = 'app.bsky.embed.external#view'
    return this.hasEmbed() && this.embed()['$type'] === type
  }

  embed() {
    return this.d['post']['embed']
  }

  hasEmbed() {
    return !!this.embed()
  }

  isRepost() {
    if (!this.d['reason']) return false

    const reason = 'app.bsky.feed.defs#reasonRepost'
    return this.d['reason']['$type'] === reason
  }
}
